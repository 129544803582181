import Script from 'next/script'
import { existsGaId, GTM_ID } from '@/utils/gtag'

export const GoogleAnalytics = () => (
  <>
    {existsGaId && (
      <>
        <Script
          defer
          src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
          strategy='afterInteractive'
        />
        <Script id='ga' defer strategy='afterInteractive'>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());    
              gtag('config', '${GTM_ID}');
          `}
        </Script>
      </>
    )}
  </>
)
