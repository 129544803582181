import Head from 'next/head'
import type { AppProps } from 'next/app'
import '@/styles/globals.scss'
import '@/utils/dayjsConfig'
import '@/utils/yupConfig'
import { GoogleAnalytics } from '@/components/GoogleAnalytics'
import { usePageView } from '@/hooks/usePageView'

function MyApp({ Component, pageProps }: AppProps) {
  usePageView()
  return (
    <>
      <Head>
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>
      <GoogleAnalytics />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
