// イベントを型で管理
type ContactEvent = {
  action: 'submit_form'
  category: 'contact'
}

type ClickEvent = {
  action: 'click'
  category: 'other'
}

export type Event = (ContactEvent | ClickEvent) & {
  label?: Record<string, string | number | boolean>
  value?: string
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER || ''

// IDが取得できない場合を想定する
export const existsGaId = GTM_ID !== ''

// PVを測定する
export const pageView = (path: string) => {
  window.gtag('config', GTM_ID, {
    page_path: path
  })
}

// GAイベントを発火させる
export const event = ({ action, category, label, value = '' }: Event) => {
  if (!existsGaId) {
    return
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value
  })
}
